import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import axiosPrivate from "../../hooks/axiosPrivate";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import Select from "react-select";
import { IoDocumentAttachOutline } from "react-icons/io5";
import styles from './DataTablePrime.module.css'
import * as Yup from "yup";
import { Calendar } from "primereact/calendar";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";


const DataTablePrime = ({ lists,  updateJobsList, supervisors  }) => {
  // State variables
  const [newList, setNewList] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [visible, setVisible] = useState(false);
  const [fileNames, setFileNames] = useState([null]);
  const [updateLinesDialog, setUpdateLinesDialog] = useState(false);
  const [supervisorId, setSupervisorId] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);
  const [updateLines, setUpdateLines] = useState(false);
  const [updateStages, setUpdateStages] = useState(false);
  const [releaseDate, setReleaseDate] = useState(null);
  const [runningStages, setRunningStages] = useState(null);
  const [jobStatus, setJobStatus] = useState(null);
  const [runningStagesDialog, setRunningStagesDialog] = useState(false);
  const [updateReleaseDateDialog, setUpdateReleaseDateDialog] = useState(false);
  const [jobStatusDialog, setJobStatusDialog] = useState(false);
  const [jobRemark, setJobRemark] = useState("");


   const userData = JSON.parse(localStorage?.getItem("userData")) || {};
 

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    customerName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    custID: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    state: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    orderType: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    country: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });

  useEffect(() => {
    // If running_stages is true, show confirmation dialog for updating release dates
    if (runningStages && releaseDate) {
      setRunningStagesDialog(true);
    }
  }, [runningStages, releaseDate]);



  useEffect(() => {
    if (runningStages === false && releaseDate) {
      setUpdateReleaseDateDialog(true);
    }
  }, [runningStages,releaseDate]);

  useEffect(() => {
    if (supervisorId) {
      setUpdateLinesDialog(true);
    }
  }, [supervisorId]);

  useEffect(() => {
    if (jobStatus) {
      setJobStatusDialog(true);
    }
  }, [jobStatus]);

  useEffect(() => {
      supervisorFormik.setFieldValue(
      "order_nums",
     selectedRows ? selectedRows?.map((job) => job.OrderNum) : []
    );
  }, [selectedRows]);

//creating orderNumbers array for dropdown
  const orderNumbers = Array.from(
    new Set(lists?.map((job) => job.OrderNum))
  ).map((orderNumber) => ({
    value: orderNumber,
    label: orderNumber,
  }));

  // Validation schema for Formik
  const validationSchema = Yup.object().shape({
    orderNum: Yup.string().required("Sales Order is required"),
    documents: Yup.array().min(1,"Document(s) is required"),
  });

  // Formik hook for documents uploading
  const uploadFormik = useFormik({
    initialValues: {
      orderNum: "",
      documents: [],
      // description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle form submission here
      const formData = new FormData();
      // formData.set("description", values.description);
      // formData.set('documents', values.documents);

      // Append each file to FormData individually
      values.documents.forEach((doc) => {
        formData.append(`documents`, doc);
      });

      // Convert prod_orders array to JSON string and append to FormData
      formData.set("orderNum", values.orderNum);

     

      try {
        const response = await axiosPrivate.post("docs", formData);
        if (response.status === 200) {
          showSuccessToast("Documents uploaded Successfully");
          setFileNames([])
          setVisible(false);
          setSelectedRows(null);
        }
      } catch (error) {
          showErrorToast(error?.response?.data?.message);
      }
      finally {
        setFileNames([]);
        setVisible(false);
        setSelectedRows(null);
      }
    },
  });


  // Effect to update the list when the data changes
  useEffect(() => {
    if (lists) {
      // Add index property to each item for numbering
      setNewList(lists.map((item, index) => ({ ...item, index: index + 1 })));
    }
  }, [lists]);


  //for global search
  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };


  const supervisorValidationSchema = Yup.object({
    order_nums: Yup.array().min(1,"Sales order is required!"),
    user_id: Yup.object().required("Supervisor is required!"),
  });

//Form handler for 
  const supervisorFormik = useFormik({
    initialValues: {
      order_nums: [],
      user_id: "",
      update_lines: false,
      update_stages: false,
    },
    validationSchema: supervisorValidationSchema,
    onSubmit: async (values) => {
      try {
        
        const response = await axiosPrivate.post("job/assign_user", {
          order_nums: values.order_nums,
          user_id: Number(values?.user_id?.value),
          update_lines: updateLines,
          update_stages: updateStages,
        });
        if (response.status === 200) {
          showSuccessToast("Supervisor Assigned Successfully");

          updateJobsList();

          setVisible(false);
          setSelectedRows(null);
        }
      } catch (error) {
        showErrorToast(error?.response?.data?.message);
      }
    },
  });


  const supervisorBodyTemplate = (rowData) => {
    const supervisor = supervisors?.find(
      (supervisor) =>
        Number(supervisor.value) === Number(rowData?.job_supervisor)
    );

    return <span>{rowData?.job_supervisor ? supervisor?.label : "-"}</span>;
  };

  const supervisorEditor = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={supervisors}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={
          options?.rowData.assigned_user
            ? supervisors?.find(
                (supervisor) =>
                  Number(supervisor.value) ===
                  Number(options.rowData?.assigned_user)
              )?.label
            : "Select Supervisor"
        }
      />
    );
  };

  const statusEditor = (options) => {
    const jobOptions =
      options?.rowData?.job_status === 1
        ? [
            {
              label: "Hold",
              value: 2,
            },
          ]
        : options?.rowData?.job_status === 2
        ? [
            {
              label: "Resume",
              value: 3,
            },
          ]
        : options?.rowData?.job_status === 0
        ? [
            {
              label: "Hold",
              value: 2,
            },
          ]
        : null;
    return (
      <Dropdown
        value={options.value}
        options={jobOptions}
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={
          options?.rowData?.job_status === 0
            ? "Pending"
            : options?.rowData?.job_status === 1
            ? "In Progress"
            : options?.rowData?.job_status === 2
            ? "Hold"
            : options?.rowData?.job_status === 3
            ? "Resume"
            : options?.rowData?.job_status === 4
            ? "Completed"
            : options?.rowData?.job_status === 5
            ? "Re-open"
            : "Pending"
        }
        itemTemplate={(option) => {
          return (
            <Tag
              value={option?.label}
              severity={
                option.value === 0
                  ? "warning"
                  : option.value === 1
                  ? "info"
                  : option.value === 2
                  ? "danger"
                  : option.value === 3
                  ? "info"
                  : option.value === 4
                  ? "success"
                  : option.value === 5
                  ? "info"
                  : "primary"
              }
            ></Tag>
          );
        }}
      />
    );
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          rowData?.job_status === 0
            ? "Pending"
            : rowData?.job_status === 1
            ? "In Progress"
            : rowData?.job_status === 2
            ? "Hold"
            : rowData?.job_status === 3
            ? "Resume"
            : rowData?.job_status === 4
            ? "Completed"
            : rowData?.job_status === 5
            ? "Re-open"
            : "Pending"
        }
        severity={
          rowData.job_status === 0
            ? "warning"
            : rowData.job_status === 1
            ? "info"
            : rowData.job_status === 2
            ? "danger"
            : rowData.job_status === 3
            ? "info"
            : rowData.job_status === 4
            ? "success"
            : rowData.job_status === 5
            ? "info"
            : "primary"
        }
      />
    );
  };

  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className='row d-flex justify-content-between align-items-center pl-2 pr-2 '>
        <div>
          {/* <button
            type='button'
            className='btn btn-primary ml-2'
            onClick={() => setVisible((prev) => !prev)}
          >
            Upload
          </button> */}

          <button
            type='button'
            className='btn btn-primary ml-2'
            onClick={() => setShowDialog((prev) => !prev)}
          >
            Assign
          </button>
        </div>
        <InputText
          type='search'
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder='Global Search'
        />
        {/* <div className=' text-right'>
          <button
            className='btn btn-primary mr-4'
            onClick={() => setShowDialog((prev) => !prev)}
          >
            Assign Supervisor
          </button>
        </div> */}
      </div>
    );
  };

  const header = renderHeader();

  const onRowEditComplete = async (e) => {
    const { newData } = e;
    
    setRunningStages(newData?.running_stages);
    setOrderNumber(newData?.OrderNum ? Number(newData?.OrderNum) : null);
    setSupervisorId(newData?.supervisor ? Number(newData?.supervisor) : null);
    setReleaseDate(newData?.releaseDate ? newData?.releaseDate : null);
    setJobStatus(newData?.new_job_status ? newData?.new_job_status : null);

   
  };

  const releaseDateBodyTemplate = (rowData) => {
    return (
      <span>
        {rowData.JobRelDt
          ? new Date(rowData.JobRelDt).toLocaleDateString("en-GB")
          : "-"}
      </span>
    );
  };

  const releaseDateEditor = (options) => {
    let maxDate = new Date(options?.rowData?.ShipDt);
    if (maxDate) {
      // Subtract one day's worth of milliseconds
      maxDate = new Date(maxDate.getTime() - 24 * 60 * 60 * 1000);
    }

    const minDate = new Date();
    minDate.setHours(0, 0, 0, 0);

    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.editorCallback(e.value)}
        // showIcon
        placeholder={
          options?.rowData?.JobRelDt
            ? new Date(options?.rowData?.JobRelDt).toLocaleDateString("en-GB")
            : "Select Date"
        }
        readOnlyInput
        dateFormat='dd/mm/yy'
        minDate={minDate}
        maxDate={maxDate}
      />
    );
  };

  const indexBodyTemplate = (rowData) => {
    return <span>{rowData.index}</span>;
  };

  const docsButtonTemplate = (rowData) => {
    const handleClick = async () => {
      await uploadFormik.setFieldValue("orderNum", rowData?.OrderNum);
      
      setVisible(true);
    };

    return (
      <IoDocumentAttachOutline
        size={30}
        color='#007BFF'
        onClick={handleClick}
        style={{
          cursor: "pointer",
          padding: "5px",
          backgroundColor: "initial",
          borderRadius: "5px",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "initial")}
      />
    );
  };

  const bomBodyTemplate = (rowData) => {
    return (
      <Tag
        value={rowData?.Shortage === 'Y' ? "Available" : "Shortage"}
        severity={rowData.Shortage === 'Y' ? "success" : "warning"}
      />
    );
  };

  const handleUpdateSupervisor = async () => {
  
    const jobArray = [];
    jobArray.push(orderNumber);

    const payload = {
      order_nums: jobArray,
      user_id: supervisorId,
      update_lines: updateLines,
      update_stages: updateStages,
    };

    try {
      const response = await axiosPrivate.post("job/assign_user", payload);
      if (response.status === 200) {
        showSuccessToast("Supervisor Assigned Successfully");
        setUpdateLinesDialog(false);
        updateJobsList();
      }
    } catch (error) {
       showErrorToast(error?.response?.data?.message);
    } finally {
      setSupervisorId(null);
      setOrderNumber(null);
      setUpdateLinesDialog(false);
    }
  };

  const handleUpdateReleaseDate = async (flag) => {
    const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
    try {
      const response = await axiosPrivate.put(
        `job/release_job/${orderNumber}`,
        {
          date: new Date(releaseDate).toLocaleDateString("en-GB", options),
          flag: flag,
        }
      );
      if (response.status === 200) {
        showSuccessToast("Job Updated Successfully");
        updateJobsList();
      }
    } catch (error) {
      showErrorToast(error?.response?.data?.message);
    } finally {
      setRunningStages(null);
      setOrderNumber(null);
      setReleaseDate(null);
      setRunningStagesDialog(false);
      setUpdateReleaseDateDialog(false);
    }
  };

  const handleJobStatusUpdate = async () => {
    if (jobRemark.length) {
      try {
        const response = await axiosPrivate.put(
          `tracking_stages/hold_resume_job/${orderNumber}`,
          {
            remarks: `${jobRemark}: ${
              userData?.userName
            }\n[${new Date().toLocaleString('en-GB')}]\n`,
            status: jobStatus,
          }
        );
        if (response.status === 200) {
          showSuccessToast("Job Updated Successfully");
          updateJobsList();
        }
      } catch (error) {
        showErrorToast(error?.response?.data?.message);
      } finally {
        setJobRemark(null);
        setOrderNumber(null);
        setJobStatusDialog(false);
        setJobRemark("");
      }
    } else {
      showErrorToast("Please enter remarks");
     
      setJobRemark("");
    }
   
  };


  const determineRowClassName = (rowData) => {
    // Check your condition here and return the appropriate class name
   
    if (rowData?.held_stages) {
      return `${styles.held_stages}` // CSS class name for completed rows
    } else {
      return ""; // Default class name for other rows
    }
  };

 
  return (
    <>
      {/* Dialog for assign supervisor Bulk Assign */}
      <Dialog
        header='Assign Supervisor'
        visible={showDialog}
        style={{ width: "35vw" }}
        onHide={() => {
          setShowDialog(false);
          supervisorFormik.resetForm();
        }}
      >
        <form
          className='form-horizontal'
          onSubmit={supervisorFormik.handleSubmit}
        >
          <div className='form-group'>
            <label className='col-sm control-label'>Sales Order</label>
            <div className='col-sm-12'>
              <Select
                options={orderNumbers}
                id='order_nums'
                name='order_nums'
                value={supervisorFormik.values?.order_nums?.map(
                  (order_num) => ({
                    value: order_num,
                    label: order_num,
                  })
                )}
                isMulti
                onChange={(selectedOptions) => {
                  supervisorFormik.setFieldValue(
                    "order_nums",
                    selectedOptions?.map((option) => option.value)
                  );
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu appears above other elements
                  menu: (base) => ({ ...base, overflow: "visible" }), // Apply overflow visible to the menu
                }}
                menuPortalTarget={document.body} // Render the menu in body to avoid clipping
              />
              {supervisorFormik.touched.order_nums &&
              supervisorFormik.errors.order_nums ? (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {supervisorFormik.errors.order_nums}
                </div>
              ) : null}
            </div>
          </div>
          <div className='form-group'>
            <label className='col-sm control-label'>Job Supervisor</label>
            <div className='col-sm-12'>
              <Select
                options={supervisors}
                value={supervisorFormik.values?.user_id}
                onChange={(selectedOption) =>
                  supervisorFormik.setFieldValue("user_id", selectedOption)
                }
                name='user_id'
                id='user_id'
                placeholder='Select Supervisor'
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the menu appears above other elements
                  menu: (base) => ({ ...base, overflow: "visible" }), // Apply overflow visible to the menu
                }}
                menuPortalTarget={document.body} // Render the menu in body to avoid clipping
              />
              {supervisorFormik.touched?.user_id &&
                supervisorFormik?.errors?.user_id && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {supervisorFormik.errors?.user_id}
                  </div>
                )}
            </div>
          </div>
          <div className='form-group'>
            <div>
              <div className='d-flex justify-content-start align-items-center mt-2'>
                <Checkbox
                  inputId='updateLinesCheckbox'
                  checked={updateLines}
                  onChange={(e) => setUpdateLines(e.checked)}
                />
                <label
                  className='ml-2 mt-1'
                  htmlFor='updateLinesCheckbox'
                >
                  Update Line Supervisors
                </label>
              </div>
              <div className='d-flex justify-content-start align-items-center'>
                <Checkbox
                  inputId='updateStagesCheckbox'
                  checked={updateStages}
                  onChange={(e) => setUpdateStages(e.checked)}
                />
                <label
                  className='ml-2 mt-1'
                  htmlFor='updateStagesCheckbox'
                >
                  Update Stage Supervisors
                </label>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-12 col-sm-offset-2 text-right pt-2'>
              <button
                type='submit'
                id='button_2'
                className='btn btn-primary'
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Dialog>

      {/* Dialog for uploading docs */}
      <Dialog
        header='Upload Documents'
        visible={visible}
        style={{ width: "35vw" }}
        onHide={() => {
          setVisible(false);
          uploadFormik.resetForm();

          setFileNames([]);
        }}
      >
        <form
          className='form-horizontal'
          onSubmit={uploadFormik.handleSubmit}
        >
          <div className='form-group'>
            <label className='col-sm control-label'>Order Number</label>
            <div className='col-sm-12'>
              <Dropdown
                name='orderNum'
                id='orderNum'
                options={orderNumbers}
                value={uploadFormik.values?.orderNum}
                onChange={(e) =>
                  uploadFormik.setFieldValue("orderNum", e.value)
                }
                placeholder='--Select Order Number--*'
                optionLabel='label'
                optionValue='value'
                filter
                disabled
                showClear
                filterBy='label'
                className='w-100'
              />
              {uploadFormik.touched?.orderNum &&
                uploadFormik.errors?.orderNum && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {uploadFormik.errors?.orderNum}
                  </div>
                )}
            </div>
          </div>

          <div className='form-group'>
            <label className='col-sm control-label'>Upload Document</label>
            <div className='col-sm-12'>
              <div className='mb-3'>
                <input
                  className='form-control'
                  type='file'
                  name='documents'
                  onBlur={uploadFormik.handleBlur}
                  multiple
                  onChange={(e) => {
                    const selectedFiles = Array.from(e.target.files);
                    const fileNames = selectedFiles.map((file) => file.name);
                    uploadFormik.setFieldValue("documents", selectedFiles);
                    setFileNames(fileNames.join(", "));
                  }}
                  id='formFile'
                />
              </div>
              {uploadFormik.touched.documents &&
                uploadFormik.errors.documents && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {uploadFormik.errors.documents}
                  </div>
                )}

              {fileNames && <div>Selected Files: {fileNames}</div>}
            </div>
          </div>

          {/* <div className='col-sm-12'>
            <div className='form-group'>
              <label className='col-sm control-label'>Description</label>
              <textarea
                name='description'
                id='description'
                placeholder='Description'
                rows={3}
                onChange={uploadFormik.handleChange}
                onBlur={uploadFormik.handleBlur}
                value={uploadFormik.values.description}
              ></textarea>
            </div>
          </div> */}
          <div className='form-group'>
            <div className='col-sm-12 col-sm-offset-2 text-right pt-2'>
              <button
                type='submit'
                id='button_2'
                className='btn btn-primary'
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Dialog>

      {/* Jobs List Data Table */}
      <DataTable
        header={header}
        value={newList}
        style={{ overflow: "scroll" }}
        editMode='row'
        onRowEditComplete={onRowEditComplete}
        paginator
        rowsPerPageOptions={[20, 40, 60, 80]}
        paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
        currentPageReportTemplate='{first} to {last} of {totalRecords}'
        selectionMode='checkbox'
        rowClassName={determineRowClassName}
        selection={selectedRows}
        onSelectionChange={(e) => setSelectedRows(e.value)}
        // onRowClick={(event) => navigate(`view/${event.data.OrderNum}`)}
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        rows={20}
        size='small'
        dataKey='OrderNum'
        tableStyle={{ minWidth: "10rem" }}
      >
        {/* Columns definition */}
        <Column
          selectionMode='multiple'
          headerStyle={{ width: "3%" }}
        />
        <Column
          field='Sr. No.'
          body={indexBodyTemplate}
          headerStyle={{ width: "6%" }}
          header='Sr. No.'
          // sortable
          style={{ width: "80px", textAlign: "left" }}
        />

        <Column
          field='CustomerName'
          header='Customer Name'
          // sortable={true}
        />
        <Column
          field='plant'
          header='Plant'
          // sortable={true}
        />
        <Column
          field='bom_status'
          header='BOM'
          headerStyle={{
            width: "7%",
            textAlign: "center",
            paddingLeft: "10px",
          }}
          // bodyStyle={{ textAlign: "center" }}
          body={bomBodyTemplate}
          // sortable
        />
        <Column
          field='OrderType'
          header='Type'
          // sortable={true}
        />
        <Column
          field='OrderNum'
          header='Sales Order'
          // sortable={true}
        />
        <Column
          field='OrderDt'
          header='Order Dt.'
          body={(rowData) =>
            rowData?.OrderDt
              ? new Date(rowData?.OrderDt).toLocaleDateString("en-GB")
              : "-"
          }
          // sortable={true}
        />
        {/* <Column
          field='ShipDt'
          header='Ship Dt.'
          body={(rowData) =>
            rowData?.ShipDt
              ? new Date(rowData?.ShipDt).toLocaleDateString("en-GB")
              : "-"
          }
          sortable={true}
        /> */}

        <Column
          field='SOTotalAmount'
          header='Total Amount'
          body={(rowData) =>
            rowData?.SOTotalAmount
              ? (rowData?.SOTotalAmount).toLocaleString("en-IN")
              : "-"
          }
          // sortable={true}
        />
        <Column
          field='supervisor'
          header='Job Supervisor'
          editor={(options) => supervisorEditor(options)}
          body={supervisorBodyTemplate}
        />

        <Column
          field='releaseDate'
          header='Job ReleaseDt.'
          editor={releaseDateEditor}
          body={releaseDateBodyTemplate}
          // sortable={true}
        />
        <Column
          field='new_job_status'
          header='Status'
          headerStyle={{ paddingLeft: "10px" }}
          // bodyStyle={{ textAlign: "center" }}
          body={statusBodyTemplate}
          editor={(options) => statusEditor(options)}
          // sortable
        />
        <Column
          body={docsButtonTemplate}
          headerStyle={{ width: "1%" }}
        />
        <Column
          field='action'
          headerStyle={{ textAlign: "right", width: "1%" }}
          bodyStyle={{ textAlign: "right" }}
        />
        <Column
          rowEditor={true}
          // headerStyle={{ textAlign: "left",}}
        />
      </DataTable>

      {/* Confirmation dialog for update Line supervisors & Stage Supervisors */}
      <Dialog
        visible={updateLinesDialog}
        onHide={() => {
          setSupervisorId(null);
          setUpdateLinesDialog(false);
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='Cancel'
              className='p-button-text mr-2'
              onClick={() => {
                // handleUpdateSupervisor();
                setUpdateLinesDialog(false);
                setSupervisorId(null);
              }}
            />
            <Button
              label='Proceed'
              className='p-button-danger'
              onClick={() => {
                handleUpdateSupervisor();
                setUpdateLinesDialog(false);
                setSupervisorId(null);
              }}
            />
          </>
        }
      >
        <div>
          <span>
            You are updating job supervisor. Do you want to update the
            following:
          </span>
          <div className='d-flex justify-content-start align-items-center mt-2'>
            <Checkbox
              inputId='updateLinesCheckbox'
              checked={updateLines}
              onChange={(e) => setUpdateLines(e.checked)}
            />
            <label
              className='ml-2 mt-1'
              htmlFor='updateLinesCheckbox'
            >
              Line Supervisors
            </label>
          </div>
          <div className='d-flex justify-content-start align-items-center'>
            <Checkbox
              inputId='updateStagesCheckbox'
              checked={updateStages}
              onChange={(e) => setUpdateStages(e.checked)}
            />
            <label
              className='ml-2 mt-1'
              htmlFor='updateStagesCheckbox'
            >
              Stage Supervisors
            </label>
          </div>
        </div>
      </Dialog>

      {/* Confirmation dialog for running stages */}
      <Dialog
        visible={runningStagesDialog}
        onHide={() => {
          setRunningStagesDialog(false);
          setReleaseDate(null);
          setRunningStages(null);
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='No'
              className='p-button-text mr-2'
              onClick={() => {
                setRunningStagesDialog(false);
                setReleaseDate(null);
                setRunningStages(null);
              }}
            />
            <Button
              label='Yes'
              className='p-button-danger'
              onClick={() => {
                setRunningStagesDialog(false);
                // setUpdateReleaseDateLines(true)
                setUpdateReleaseDateDialog(true);
              }}
            />
          </>
        }
      >
        <p>
          Running stages detected for this Sales Order. Do you still want to
          update the release date!
        </p>
      </Dialog>

      {/* Confirmation dialog for updating release dates */}
      <Dialog
        visible={updateReleaseDateDialog}
        onHide={() => {
          setUpdateReleaseDateDialog(false);
          setReleaseDate(null);
          setRunningStages(null);
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='No'
              className='p-button-text mr-2'
              onClick={() => {
                handleUpdateReleaseDate(false);
                setReleaseDate(null);
                setRunningStages(null);
                setUpdateReleaseDateDialog(false);
              }}
            />
            <Button
              label='Yes'
              className='p-button-danger'
              onClick={() => {
                handleUpdateReleaseDate(true);
                setUpdateReleaseDateDialog(false);
                setReleaseDate(null);
                setRunningStages(null);
              }}
            />
          </>
        }
      >
        <span>Do you want to update the release Date for lines also!</span>
        {/* <div className="d-flex justify-content-start align-items-center">
          
          <Checkbox
            inputId='updateLinesCheckbox'
            checked={updateLines}
            onChange={(e) => setUpdateLines(e.checked)}
          />
          <label htmlFor='updateLinesCheckbox' className="ml-2 mt-1">
            Update Release Dates for Lines
          </label>
        </div> */}
      </Dialog>

      {/* Confirmation dialog for job status update */}
      <Dialog
        visible={jobStatusDialog}
        onHide={() => {
          setJobStatusDialog(false);
          setJobStatus(null);
          setJobRemark("");
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='Cancel'
              className='p-button-text mr-2'
              onClick={() => {
                setJobStatusDialog(false);
                setJobStatus(null);
                setJobRemark("");
              }}
            />
            <Button
              label='Proceed'
              className='p-button-danger'
              onClick={() => {
                handleJobStatusUpdate();
              }}
            />
          </>
        }
      >
        <div>
          <span>Are you sure you want to hold this job!</span>
          <div className='d-flex justify-content-start align-items-start flex-column '>
            <InputTextarea
              value={jobRemark}
              onChange={(e) => setJobRemark(e.target.value)}
              rows={3}
              cols={60}
            />
            {!jobRemark.length ? (
              <span
                span
                style={{ color: "red", fontSize: "12px" }}
              >
                Remarks required!
              </span>
            ) : null}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DataTablePrime;
