import React, { useEffect, useState } from "react";
import { showErrorToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { Calendar } from "primereact/calendar";
import { Chart } from "primereact/chart";

const CurrentJobsGraph = () => {
  const [chartData, setChartData] = useState([]);

  const fetchChartData = async () => {
    try {
      const response = await axiosPrivate.get("dashboard/all_jobs", {});
      if (response.status === 200) {
        setChartData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  const [jobsChartData, setJobsChartData] = useState({});
  const [jobsChartOptions, setJobsChartOptions] = useState({});
  useEffect(() => {
    const jobsData = {
      labels: [
        "Ongoing Jobs",
        "Jobs On Hold",
        "Jobs Completed",
        "Pending Jobs",
      ],
      datasets: [
        {
          label: "Jobs",
          data: chartData,
          backgroundColor: [
            "rgb(191, 80, 78,0.5)",
            "rgb(255, 99, 26 ,0.5)",
            "rgb(0, 175, 80,0.5)",
            "rgb(62, 109, 225,0.5)",
          ],
        },
      ],
    };

    const jobsOptionsData = {
      legend: {
        position: "bottom",
      },
    };

    setJobsChartData(jobsData);
    setJobsChartOptions(jobsOptionsData);
  }, [chartData]);

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title'>Production</h3>
      </div>
      <div className='card-body '>
        {/* <div className='col-xl-12 col-lg-12 col-md-12'>
          <div className='dropdown text-right w-100'>
            <select
              className='btn btn-secondary dropdown-toggle'
              onChange={handleRangeChange}
              value={selectedRange}
            >
              <option value='This Month'>This Month</option>
              <option value='Last Month'>Last Month</option>
              <option value='Last 3 Months'>Last 3 Months</option>
              <option value='Custom'>Custom</option>
            </select>
           
            {selectedRange === "Custom" && (
              <div className='d-flex flex-row justify-content-between align-items-end w-100 mt-2'>
                <div>
                  <label className='form-label text-left'>From:</label>
                  <Calendar
                    value={fromDate}
                    onChange={(e) => setFromDate(e.value)}
                    showIcon
                  />
                </div>
                <div>
                  <label className='form-label'>To:</label>
                  <Calendar
                    value={toDate}
                    onChange={(e) => setToDate(e.value)}
                    showIcon
                  />
                </div>
              </div>
            )}
          </div>
        </div> */}
        {/* Log jobsChartOptions here to check if it's correctly set */}
        {console.log("jobsChartOptions:", jobsChartOptions)}
        <Chart
          type='pie'
          data={jobsChartData}
          className='w-full md:w-30rem'
          options={jobsChartOptions}
        />
      </div>
    </div>
  );
};

export default CurrentJobsGraph;
