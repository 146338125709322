import React, { useEffect, useState } from "react";
import "./job.module.css";
import JobLines from "./JobLines";
import StagesSection from "./StagesSection";
import { useParams } from "react-router-dom";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import { TabPanel, TabView } from "primereact/tabview";
import UploadsViewPage from "./UploadsViewPage";

const JobView = () => {
  const [jobData, setJobData] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [supervisors, setSupervisors] = useState([]);

  const { id } = useParams();
  const orderNum = id;

  const { userId } = JSON.parse(localStorage.getItem("userData"));

  const updateJobData = () => {
    fetchJobData();
  };

  const fetchJobData = async () => {
    try {
      if (orderNum) {
        setIsLoading(true); // Set isLoading to true before fetching data
        const response = await axiosPrivate.get(`job/${orderNum}`);
        setJobData(response?.data?.data);
      }
    } catch (err) {
      showErrorToast(err.message);
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching data (whether success or error)
    }
  };

  useEffect(() => {
    fetchJobData();
  }, []);

  const fetchSupervisorsData = async () => {
    try {
      const response = await axiosPrivate.get(`users/supervisors/${userId}`);
      if (response.status === 200) {
        setSupervisors(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSupervisorsData();
  }, []);

  return (
    <>
      {/* Conditionally render Loader component based on isLoading state */}
      {isLoading ? (
        <Loader />
      ) : (
        <TabView>
          <TabPanel header='Job Info'>
            <div className='card bg-light '>
              <div className='card-body'>
                <div
                  className='row col-12 ml-1'
                  style={{ color: "black" }}
                >
                  <div className='col-md-4 '>
                    <p>Order Type: {jobData[0]?.OrderType}</p>
                    <p>Customer ID: {jobData[0]?.CustID}</p>
                    <p>Country: {jobData[0]?.Country}</p>
                  </div>
                  <div className='col-md-4'>
                    <p>
                      Order Date:{" "}
                      {new Date(jobData[0]?.OrderDt).toLocaleDateString(
                        "en-GB"
                      )}
                    </p>
                    <p>Customer Name: {jobData[0]?.CustomerName}</p>
                    <p>Order Number: {jobData[0]?.OrderNum}</p>
                  </div>
                  <div className='col-md-4'>
                    <p>State: {jobData[0]?.State}</p>
                    <p>Office: {jobData[0]?.Office}</p>
                    <p>Engineer: {jobData[0]?.Engineer}</p>
                    {/* <p>
                      Ship Date:{" "}
                      {new Date(jobData[0]?.ShipDt).toLocaleDateString("en-GB")}
                    </p> */}
                  </div>
                </div>

                <JobLines
                  jobLinesData={jobData}
                  updateJobData={updateJobData}
                  supervisors={supervisors}
                />

                <StagesSection
                  jobData={jobData}
                  updateJobData={updateJobData}
                  supervisors={supervisors}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel header='Uploads'>
            {/* Render UploadsViewPage component */}
            <UploadsViewPage orderNum={orderNum} />
          </TabPanel>
        </TabView>
      )}
    </>
  );
};

export default JobView;
