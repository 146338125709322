import React, { useEffect, useState } from "react";
import TopNavBar from "../../Layouts/TopNavBar";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";

const plantSchema = Yup.object({
  name: Yup.string().required("Name is Required"),
  code: Yup.string().required("Code is Required"),
  // description: Yup.string().required("Description is Required"),
  company_id: Yup.string().required("Company is Required"),
});
const plantsForm = {
  name: "",
  description: "",
  code: "",
  company_id: "",
};

const AddEditPlant = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [companiesData, setCompaniesData] = useState([]);

  // Fetch Plant data if id is provided and user wants to update
  useEffect(() => {
    const getPlant = async () => {
      try {
        if (id) {
          const res = await axiosPrivate.get(`plant/${id}`);
          setValues(res?.data?.data);
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    };

    if (id) {
      getPlant();
    }
  }, [id]);

  // Function to fetch company data for dropdown
  const fetchData = async () => {
    try {
      const response = await axiosPrivate.get("company");
      if (response.status === 200) {
        setCompaniesData(response?.data?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: plantsForm,
    validationSchema: plantSchema,
    onSubmit: async (values) => {
      try {
        if (id) {
          await axiosPrivate.put(`plant/${id}`, values);
          navigate("/plant");
          showSuccessToast("Plant updated successfully");
        } else {
          await axiosPrivate.post(`plant`, values);
          navigate("/plant");
          showSuccessToast("Plant added successfully");
        }
      } catch (err) {
        showErrorToast(err.message);
      }
    },
  });

  return (
    <div className='content'>
      <div
        className='section-body d-flex justify-content-center align-items-center'
        style={{ minHeight: `calc(100vh - 106px)` }}
      >
        <form onSubmit={handleSubmit}>
          <div
            className='card  '
            style={{ width: "30vw" }}
          >
            <div className='card-header'>
              <strong>Add Plants</strong>
            </div>
            <div className='card-body'>
              <div className='row clearfix'>
                <div className='col-12'>
                  <div className='form-group'>
                    <label className='form-label'>Select Company</label>
                    <select
                      className='form-control show-tick'
                      name='company_id'
                      id='company'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.company_id}
                    >
                      <option value=''>--Select Company--</option>
                      {companiesData.map((company, index) => (
                        <option
                          key={index}
                          value={company.id}
                        >
                          {company.name}
                        </option>
                      ))}
                    </select>
                    {touched.company_id && errors.company_id && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.company_id}
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Plant Code</label>
                    <input
                      id='code'
                      name='code'
                      type='number'
                      className='form-control'
                      placeholder='Plant-Code *'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                    />
                  </div>
                  {touched.code && errors.code ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.code}
                    </div>
                  ) : null}
                </div>
                <div className='col-md-6 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Plant Name</label>
                    <input
                      id='name'
                      name='name'
                      type='text'
                      className='form-control'
                      placeholder='Plant-Name *'
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                        setFieldValue("name", values.name.trim());
                      }}
                      value={values.name}
                    />
                  </div>
                  {touched.name && errors.name ? (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.name}
                    </div>
                  ) : null}
                </div>
                <div className='col-md-12 col-sm-12'>
                  <div className='form-group'>
                    <label className='form-label'>Plant Description</label>
                    <textarea
                      name='description'
                      id='description'
                      placeholder='Plant-Description '
                      rows={3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    ></textarea>
                  </div>
                  {touched.description && errors.description && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.description}
                    </div>
                  )}
                </div>
                <div className='col-12 text-right'>
                  <hr className='mt-4' />

                  <button
                    type='button'
                    id='button_1'
                    className='btn btn-secondary mx-1 '
                    data-dismiss='modal'
                    onClick={() => navigate("/plant")}
                  >
                    CLOSE
                  </button>
                  <button
                    type='submit'
                    id='button_2'
                    className='btn btn-primary'
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditPlant;
