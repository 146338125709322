import React, { useEffect, useState } from "react";
import { showErrorToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { Calendar } from "primereact/calendar";
import { Chart } from "primereact/chart";

const SupervisorLoadGraph = () => {
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [supervisorLoadData, setSupervisorLoadData] = useState({});

  const fetchChartData = async () => {
    try {
      const response = await axiosPrivate.get("dashboard/supervisor_load");
      if (response.status === 200) {
        setChartData(response?.data?.data);
      }
    } catch (error) {
      if (error?.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  useEffect(() => {
    const data = {
      labels: chartData?.map((supervisor) => supervisor.name),
      datasets: [
        {
          label: "In Progress Jobs",
          backgroundColor: "rgb(62, 109, 225,0.4)",
          borderColor: "rgb(62, 109, 225,0.4)",
          data: chartData?.map((supervisorData) => supervisorData?.in_progress),
        },
        {
          label: "Pending Jobs",
          backgroundColor: "rgb(191, 80, 78,1)",
          borderColor: "rgb(191, 80, 78,1)",
          data: chartData?.map((supervisorData) => supervisorData?.pending),
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };

    setSupervisorLoadData(data);
    setChartOptions(options);
  }, [chartData]);

  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title justify-content-center'>
          Load On Supervisors
        </h3>
      </div>
      <div className='card-body'>
        {/* <div className='col-xl-12 col-lg-12 col-md-12'>
          <div className='dropdown text-right w-100'>
            <select
              className='btn btn-secondary dropdown-toggle'
              onChange={handleRangeChange}
              value={selectedRange}
            >
              <option value='This Month'>This Month</option>
              <option value='Last Month'>Last Month</option>
              <option value='Last 3 Months'>Last 3 Months</option>
              <option value='Custom'>Custom</option>
            </select>
           
            {selectedRange === "Custom" && (
              <div className='d-flex flex-row justify-content-between align-items-end w-100 mt-2'>
                <div>
                  <label className='form-label text-left'>From:</label>
                  <Calendar
                    value={fromDate}
                    onChange={(e) => setFromDate(e.value)}
                    showIcon
                  />
                </div>
                <div>
                  <label className='form-label'>To:</label>
                  <Calendar
                    value={toDate}
                    onChange={(e) => setToDate(e.value)}
                    showIcon
                  />
                </div>
              </div>
            )}
          </div>
        </div> */}
        <Chart
          type='bar'
          data={supervisorLoadData}
          options={chartOptions}
          className='w-full'
        />
      </div>
    </div>
  );
};

export default SupervisorLoadGraph;
