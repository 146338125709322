import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Tag } from "primereact/tag";
import { useFormik } from "formik";
import * as Yup from "yup";
import DataTableStages from "../../components/DataTableStages/DataTableStages";
import { FcClearFilters } from "react-icons/fc";
import Select from "react-select";
import { Dialog } from "primereact/dialog";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { useNavigate } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";

const StagesSection = ({ jobData, updateJobData, supervisors }) => {
 
  const [filterStatus, setFilterStatus] = useState([]);
  const [stagesData, setStagesData] = useState([]);
  const [filterSupervisor, setFilterSupervisor] = useState([]);
  const [filterProductionOrder, setFilterProductionOrder] = useState([]);
  const [filteredStages, setFilteredStages] = useState([]);

  useEffect(() => {
    setFilteredStages(() => jobData?.map((line) => line.stages).flat());
    setStagesData(() => jobData?.map((line) => line.stages).flat());
  }, [jobData]);

  const statusOptions = [...new Set(filteredStages?.map((stage) => stage.status))];

  // const supervisorOptions = ["Ganga Singh", "Arun Bolia"];
  const productionOrderOptions = Array.from(
    new Set(filteredStages?.map((stage) => stage.production_order))
  );

  const applyFilters = () => {
    let filteredData = filteredStages;

    // Apply status filter
    if (filterStatus.length > 0) {
      filteredData = filteredData.filter((stage) =>
        filterStatus.includes(stage.status)
      );
    }

    // Apply supervisor filter
    if (filterSupervisor.length > 0) {
      filteredData = filteredData.filter((stage) =>
        filterSupervisor
          .map((supervisor) => Number(supervisor.value))
          .includes(stage.assigned_user)
      );
    }

    // Apply production order filter
    if (filterProductionOrder.length > 0) {
      filteredData = filteredData.filter((stage) =>
        filterProductionOrder.includes(stage.production_order)
      );
    }

    setFilteredStages(filteredData);
  };

  const clearFilters = () => {
    setFilterStatus([]);
    setFilterSupervisor([]);
    setFilterProductionOrder([]);
    setFilteredStages(stagesData);
  };

  // const renderError = (fieldName) => {
  //   return formik.touched[fieldName] && formik.errors[fieldName] ? (
  //     <div style={{ color: "red", fontSize: "12px" }}>
  //       {formik.errors[fieldName]}
  //     </div>
  //   ) : null;
  // };
  const stageDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`stage/line/${id}`);
      if (response.status === 200) {
        showSuccessToast("Stage deleted successfully");
        updateJobData();
      }
    } catch (error) {
      showErrorToast(
        error?.response?.data?.errors?.length
          ? error?.response?.data?.errors[0]?.msg
          : "Something went wrong!"
      );
    }
  };

  const stagesListData = filteredStages?.map((value) => {
    let buttons = [];

    // Add view button
    buttons.push(
      <AiOutlineDelete
        key={`deleteButton_${value.id}`}
        onClick={() => stageDeleteHandler(value?.id)}
        size={30}
        style={{
          cursor: "pointer",
          padding: "5px",

          backgroundColor: "initial",
          borderRadius: "5px",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "initial")}
        color='#ff0000'
      />
    );

    value["action"] = buttons.length > 0 ? buttons : "-";

    return value;
  });

  return (
    <div className='col-12'>
      <div className='card'>
        <div className='card-header '>
          <h5>Stages</h5>
        </div>

        <div className='card-body d-flex flex-column'>
          <div
            className='filter-section col-12 pl-3 pr-3 d-flex flex-column rounded-3  mb-2 h-100'
            style={{ borderRadius: "5px" }}
          >
            <div className='d-flex justify-content-between align-items-center col-12'>
              {/* Status filter */}
              <div className='form-group  col-3'>
                <label className='form-label'>Status:</label>

                <Select
                  options={statusOptions?.map((status) => ({
                    value: status,
                    label:
                      status === 0
                        ? "Pending"
                        : status === 1
                        ? "In Progress"
                        : status === 2
                        ? "Hold"
                        : status === 3
                        ? "Resume"
                        : status === 4
                        ? "Completed"
                        : status === 5
                        ? "Re-open"
                        : "Pending",
                  }))}
                  isMulti
                  value={filterStatus?.map((value) => ({
                    value: value,
                    label:
                      value === 0
                        ? "Pending"
                        : value === 1
                        ? "In Progress"
                        : value === 2
                        ? "Hold"
                        : value === 3
                        ? "Resume"
                        : value === 4
                        ? "Completed"
                        : value === 5
                        ? "Re-open"
                        : "Pending",
                  }))}
                  onChange={(selectedOptions) => {
                    setFilterStatus(
                      selectedOptions
                        ? selectedOptions?.map((option) => option.value)
                        : []
                    );
                  }}
                />
              </div>
              {/* Supervisor filter */}
              <div className='form-group  col-3'>
                <label className='form-label'>Supervisor:</label>
                {/* Supervisor multi-select */}
                <Select
                  options={supervisors}
                  isMulti
                  value={filterSupervisor?.map((supervisor) => ({
                    value: supervisor.value,
                    label: supervisor.label,
                  }))}
                  onChange={(selectedOptions) => {
                    const filteredOptions = selectedOptions?.map((option) => ({
                      value: option.value,
                      label: option.label,
                    }));
                    setFilterSupervisor(filteredOptions ?? []);
                  }}
                />
              </div>
              {/* Production Order filter */}
              <div className='form-group  col-3'>
                <label className='form-label'>Production Order:</label>
                {/* Production Order multi-select */}
                <Select
                  options={productionOrderOptions?.map((order) => ({
                    value: order,
                    label: order,
                  }))}
                  value={filterProductionOrder?.map((order) => ({
                    value: order,
                    label: order,
                  }))}
                  isMulti
                  onChange={(selectedOptions) => {
                    setFilterProductionOrder(
                      selectedOptions
                        ? selectedOptions?.map((option) => option.value)
                        : []
                    );
                  }}
                />
              </div>
              {/* Apply filters button */}
              <div className='d-flex col-1 justify-content-between align-items-center'>
                <button
                  className='btn btn-primary  mt-2'
                  onClick={applyFilters}
                >
                  Apply
                </button>
                <FcClearFilters
                  size={25}
                  onClick={clearFilters}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          <div className='table-responsive col-12 mb-4 '>
            <DataTableStages
              lists={stagesListData}
              linesData={jobData}
              updateJobData={updateJobData}
              supervisors={supervisors}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StagesSection;
