import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import axiosPrivate from "../../hooks/axiosPrivate";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const ProductCategory = () => {
  const [productCategoryData, setProductCategoryData] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [productCategoryId, setProductCategoryId] = useState(null);
  const { authPermissions } = usePermissions();
  const [filters, setFilters] = useState({
    global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  });

  // Function to handle product category deletion
  // const productCategoryDeleteHandler = async (id) => {
  //   try {
  //     const response = await axiosPrivate.delete(`/${id}`);
  //     if (response.status === 200) {
  //       fetchData();
  //     }
  //   } catch (error) {
  //     console.error("Error deleting product category:", error);
  //   }
  // };
  useEffect(() => {
    if (productCategoryId) {
      setDeleteConfirmationVisible(true);
    }
  }, [productCategoryId]);  
  const productCategoryDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`product_category/${id}`);
      if (response.status === 200) {
        showSuccessToast("Product category deleted successfully");
        fetchData();

      }
    } catch (error) {
      showErrorToast(error.response?.data?.errors[0]?.msg);
    } finally {
      setProductCategoryId(null)
    }
  };

  // Function to fetch product category data
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("product_category");
      if (response.status === 200) {
        setProductCategoryData(response.data?.data);
      }
    } catch (error) {
      showErrorToast(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Prepare data for rendering in DataTable
  const productCategoryListData = productCategoryData?.map(
    (category, index) => {
      let buttons = [];

      // Add edit button
      if (authPermissions?.includes("Product Categories-Update")) {
        buttons.push(
          <Link
            key={`editButton_${category.id}`}
            to={`/productcategory/edit/${category.id}`}
            className='btn btn-link'
            style={{ border: "none", background: "none", padding: "0" }}
            title='Edit'
          >
            <FaEdit
              color='green'
              size={13}
            />
          </Link>
        );
      }

      // Add delete button
      // if (authPermissions?.includes("Product Categories-Delete")) {
      //   buttons.push(
      //     <button
      //       key={`deleteButton_${category.id}`}
      //       className='btn btn-link m-0'
      //       style={{ border: "none", background: "none" }}
      //       onClick={() => productCategoryDeleteHandler(category.id)}
      //       title='Delete'
      //     >
      //       <FaTrash
      //         color='red'
      //         size={13}
      //       />
      //     </button>
      //   );
      // }

      return { ...category, index: index + 1, action: buttons };
    }
  );

  // Function to handle global filter change
  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  // Render global search input
  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className='d-flex justify-content-between w-100'>
        {authPermissions?.includes("Product Categories-Create") && (
          <Link
            to='add'
            className='text-decoration-none'
          >
            <button
              type='button'
              className='btn btn-primary'
            >
              <i className='fe fe-plus mr-2' />
              Add
            </button>
          </Link>
        )}
        <InputText
          type='search'
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder='Global Search'
        />
      </div>
    );
  };

  const header = renderHeader();

  const deleteBodyTemplate = (rowData) => {
    return (
      <button
        type='button'
        onClick={() => {
          setProductCategoryId(rowData.id);
        }}
        title='Delete'
        style={{ border: "none", background: "none", padding: "6px", textAlign: "left" }}
      >
        <FaTrash
          color='red'
          size={13}

        />
      </button>
    );
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='content'>
          <div className='page-header'>
            <h4 className='m-1 pl-3'>Product Groups</h4>
          </div>
          <div className='card'>
            <div className='card-body'>
              <div className='table-responsive'>
                <DataTable
                  value={productCategoryListData}
                  dataKey='id'
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 50, 100, 150]}
                  paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                  currentPageReportTemplate='{first} to {last} of {totalRecords}'
                >
                  <Column
                    field='index'
                    header='Sr. No.'
                    headerStyle={{ width: "10%" }}
                    sortable
                    bodyStyle={{ textAlign: "left" }}
                  />
                  <Column
                    field='product_category_name'
                    header='Product Category'
                    sortable
                  />
                  <Column
                    field='supervisor_name'
                    header='Line Supervisor'
                    sortable
                  />
                  <Column
                    field='plant_name'
                    header='Plant'
                    sortable
                  />
                  <Column
                    field='action'
                    header='Action'
                    headerStyle={{ width: "3%", paddingLeft: '65px' }}
                    bodyStyle={{ textAlign: "right" }}
                    />
                    {
                      authPermissions.includes('Product Categories-Delete') && (
                        <Column
                          field='delete_handler'
                          body={deleteBodyTemplate}
                          headerStyle={{ width: "15%" }}
                          bodyStyle={{ textAlign: "left" }}
                        />
                      )
                    }
                
                </DataTable>
              </div>
            </div>
          </div>
          <Dialog
        visible={deleteConfirmationVisible}
        onHide={() => {
          setDeleteConfirmationVisible(false);
          setProductCategoryId(null);
        }}
        header='Confirmation'
        footer={
          <>
            <Button
              label='Cancel'
              className='p-button-text mr-2'
              onClick={() => {
                setProductCategoryId(null);
                setDeleteConfirmationVisible(false)
              }}
            />
            <Button
              label='Delete'
              className='p-button-danger'
              onClick={() => {
                productCategoryDeleteHandler(productCategoryId);
                setDeleteConfirmationVisible(false)
              }}
            />
          </>
        }
      >
        <p>Are you sure you want to delete this product category?</p>
      </Dialog>
        </div>
      )}
    </>
  );
};

export default ProductCategory;
