import React, { useEffect } from "react";
import DataTableLines from "../../components/DataTableLines/DataTableLines";
import { FcClearFilters } from "react-icons/fc";
import Select from "react-select";
import { useState } from "react";

// Define headers for the DataTableLines component
const headers = [
  {
    name: "Order Line",
    field: "OrderLine",
    sortable: true,
    classKey: "",
  },
  {
    name: "Production Order",
    field: "ProductionOrder",
    sortable: true,
    classKey: "",
  },
  {
    name: "Order Quantity",
    field: "OrderQty",
    sortable: true,
    classKey: "",
  },
  {
    name: "Product Group",
    field: "ProdGroup",
    sortable: true,
    classKey: "",
  },
  {
    name: "Line Supervisor",
    field: "LineSupervisor",
    sortable: true,
    classKey: "",
  },
];

const JobLines = ({ jobLinesData, updateJobData, supervisors }) => {
  // State variables to store filters and filtered jobs
  const [filterProductGroup, setFilterProductGroup] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState(jobLinesData);
  const [filterSupervisor, setFilterSupervisor] = useState([]);
  // const [selectedOrderLines, setSelectedOrderLines] = useState([]);
  const [selectedProductionOrders, setSelectedProductionOrders] = useState([]);

  // Get unique order lines and production orders from job lines data

  const productCategories = Array.from(
    new Set(jobLinesData.map((job) => job.ProdGroup))
  );
  // const orderLines = Array.from(
  //   new Set(jobLinesData.map((job) => job.OrderLine))
  // ).map((orderLine) => ({ value: orderLine, label: orderLine }));

  const productionOrders = Array.from(
    new Set(jobLinesData.map((job) => job.ProductionOrder))
  ).map((productionOrder) => ({
    value: productionOrder,
    label: productionOrder,
  }));

  // Function to apply filters
  const applyFilters = () => {
    let filteredData = jobLinesData;

    // Apply product group filter
    if (filterProductGroup.length > 0) {
      filteredData = filteredData.filter((job) =>
        filterProductGroup.includes(job.ProdGroup)
      );    
    }

    // Apply supervisor filter
    if (filterSupervisor.length > 0) {
      filteredData = filteredData.filter((stage) =>
        filterSupervisor
          .map((supervisor) => Number(supervisor.value))
          .includes(stage.assigned_user)
      );
    }

    // Apply production order filter
    if (selectedProductionOrders.length > 0) {
      filteredData = filteredData.filter((job) =>
        selectedProductionOrders.includes(job.ProductionOrder)
      );
    }

    // Update filtered jobs state
    setFilteredJobs(filteredData);
  };

  // Function to clear filters
  const clearFilters = () => {
    setFilterProductGroup([]);
    setFilterSupervisor([]);
    setSelectedProductionOrders([]);
    setFilteredJobs(jobLinesData);
  };

  return (
    <div className='col-12'>
      <div className='card'>
        <div className='card-header'>
          <h5>Lines</h5>
        </div>
        <div className='d-flex flex-column card-body'>
          <div className='filter-section col-12 pl-3 pr-3 d-flex flex-column  rounded-3  mb-2 h-100'>
            <div className='d-flex justify-content-between align-items-center col-12'>
              <div className='form-group col-3'>
                <label className='form-label'>Product Group:</label>
                <Select
                  options={productCategories.map((group) => ({
                    value: group,
                    label: group,
                  }))}
                  value={filterProductGroup.map((group) => ({
                    value: group,
                    label: group,
                  }))}
                  isMulti
                  onChange={(selectedOptions) => {
                    setFilterProductGroup(
                      selectedOptions.map((option) => option.value)
                    );
                  }}
                />
              </div>
              {/* Supervisor filter */}
              <div className='form-group  col-3'>
                <label className='form-label'>Supervisor:</label>
                {/* Supervisor multi-select */}
                <Select
                  options={supervisors}
                  isMulti
                  value={filterSupervisor?.map((supervisor) => ({
                    value: supervisor.value,
                    label: supervisor.label,
                  }))}
                  onChange={(selectedOptions) => {
                    const filteredOptions = selectedOptions?.map((option) => ({
                      value: option.value,
                      label: option.label,
                    }));
                    setFilterSupervisor(filteredOptions ?? []);
                  }}
                />
              </div>

              <div className='form-group col-3'>
                <label className='form-label'>Production Order:</label>
                <Select
                  options={productionOrders}
                  value={selectedProductionOrders.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  isMulti
                  onChange={(selectedOptions) => {
                    setSelectedProductionOrders(
                      selectedOptions?.map((option) => option.value)
                    );
                  }}
                />
              </div>
              <div className='d-flex col-1 justify-content-between align-items-center'>
                <button
                  className='btn btn-primary mt-2 '
                  onClick={applyFilters}
                >
                  Apply
                </button>
                <div>
                  <FcClearFilters
                    size={25}
                    onClick={clearFilters}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='table-responsive col-12 mb-4 '>
            <DataTableLines
              updateJobData={updateJobData}
              lists={filteredJobs}
              headers={headers}
              supervisors={supervisors}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobLines;
