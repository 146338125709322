import React, { useEffect, useState } from "react";
import { FcClearFilters } from "react-icons/fc";
import { TabView, TabPanel } from "primereact/tabview";
import DataTablePrime from "../../components/DataTablePrime/DataTablePrime";
import Select from "react-select";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import Loader from "../../context/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { GrFormView } from "react-icons/gr";
import DataTableLines from "../../components/DataTableLines/DataTableLines";
import DataTableRequests from "../../components/DataTableRequests/DataTableRequests";

const headers = [
  // Define headers for the DataTable component
  { name: "Customer Name", field: "CustomerName", sortable: true },
  { name: "Job No.", field: "OrderNum", sortable: true },
  { name: "Order Type", field: "OrderType", sortable: true },
  { name: "Amount", field: "IndianAmt", sortable: true },
  { name: "Order Date", field: "OrderDt", sortable: true },
  { name: "Release Date", field: "ProductionRelDt", sortable: true },
  { name: "ACTION", field: "action" },
];



const JobsList = () => {
  // State variables
  const [activeTabIndex, setActiveTabIndex] = useState(0); // State to track active tab index
  const [filterOrderType, setFilterOrderType] = useState([]);
  const [filterOrderNum, setFilterOrderNum] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [linesData, setLinesData] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [requestsData, setRequestsData] = useState([]);
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  const statusOptions = [
    ...new Set(filteredJobs?.map((line) => line.job_status)),
  ];

  // Fetch jobs data from API
  const fetchJobsData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("job");
      if (response.status === 200) {
        const Data = response?.data?.data || [];
        setLinesData(Data);
        const orderNumMap = new Map();
        const SoAmountMap = new Map();

        //creating Map to calculate the total amount of one SO
        Data.forEach(line => {
          if (SoAmountMap.has(line.OrderNum)) {
            SoAmountMap.set(
              line.OrderNum,
              SoAmountMap.get(line.OrderNum) +
                Number(line.IndianAmt.replaceAll(",", ""))
            );            
          } else {
            SoAmountMap.set(
              line.OrderNum,
              Number(line.IndianAmt.replaceAll(",", ""))
            );
          }
        })
               

        //creating Map for job orders where orderNum will contain the data of one line
        Data.forEach((line) => {
          if (!orderNumMap.has(line.OrderNum)) {

            orderNumMap.set(line.OrderNum, {...line , SOTotalAmount: SoAmountMap.get(line.OrderNum) ? SoAmountMap.get(line.OrderNum) : '-'});
          }
        });
       
        setJobsData([...orderNumMap.values()].sort((a,b)=> b.held_stages - a.held_stages));
        setFilteredJobs([...orderNumMap.values()].sort((a,b)=> b.held_stages - a.held_stages));
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateJobsList = () => {
    fetchJobsData();
    clearFilters();
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchJobsData();
  }, []);

  const updateLinesData = () => {
    fetchJobsData();
    clearFilters();
  };

  // Update filtered jobs when jobs data changes
  useEffect(() => {
    setFilteredJobs(jobsData);
  }, [jobsData]);

  useEffect(() => {
    setProductCategories([...new Set(jobsData?.map((job) => job.ProdGroup))]);
  }, [jobsData]);

  const fetchSupervisorsData = async () => {
    try {
      const response = await axiosPrivate.get(
        `users/supervisors/${userData?.userId}`
      );
      if (response.status === 200) {
        setSupervisors(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSupervisorsData();
  }, []);

  const fetchRequestsData = async () => {
    try {
      const response = await axiosPrivate.get(
        "tracking_stages/get_reopen_requests"
      );
      if (response.status === 200) {
        setRequestsData(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }     
    
    }
  };

  useEffect(() => {
    // Fetch data when component mounts
    fetchRequestsData();
  }, []);

   const updateRequestsData = () => {
     fetchRequestsData();
   };

  // Get unique order types from jobs data
  const orderTypesData = Array.from(
    new Set(jobsData?.map((job) => job.OrderType))
  );

  // Apply filters to jobs data
  const applyFilters = () => {
    let filteredData = jobsData;

    if (filterOrderType.length > 0) {
      filteredData = filteredData.filter((job) =>
        filterOrderType.includes(job.OrderType)
      );
    }

    if (filterOrderNum.length > 0) {
      filteredData = filteredData.filter((job) =>
        filterOrderNum.includes(job.OrderNum)
      );
    }

    if (filterStatus.length > 0) {
      filteredData = filteredData.filter((job) =>
        filterStatus.includes(job.job_status)
      );
    }

    setFilteredJobs(filteredData);
  };

  // Clear all filters
  const clearFilters = () => {
    setFilterOrderType([]);
    setFilterOrderNum([]);
    setFilterStatus([]);
    setFilteredJobs(jobsData);
  };

  // Generate job list data with view buttons
  const jobsListData = filteredJobs?.map((value) => ({
    ...value,
    action: (
      <Link
        key={`viewButton_${value.id}`}
        to={`view/${value.OrderNum}`}
        style={{ border: "none", background: "none" }}
        title='View'
      >
        <GrFormView
          size={30}
          color='#ff0000'
        />
      </Link>
    ),
  }));

  return (
    <>
      {/* Conditionally render Loader component based on isLoading state */}
      {isLoading ? (
        <Loader />
      ) : (
        <div className='content'>
          <div className='card'>
            <TabView
              activeIndex={activeTabIndex}
              onTabChange={(e) => setActiveTabIndex(e.index)}
            >
              {/* Tab for Sales Orders */}
              <TabPanel header='Sales Orders'>
                <div className='col-12 mt-2'>
                  <div className=' d-flex flex-column'>
                    {/* Filter section */}
                    <div className='filter-section col-12 d-flex flex-column rounded-3'>
                      <div className='d-flex justify-content-between align-items-center col-12'>
                        {/* Order Type filter */}
                        <div className='form-group col-3'>
                          <label className='form-label'>Order Type:</label>
                          <Select
                            options={orderTypesData.map((type) => ({
                              value: type,
                              label: type,
                            }))}
                            isMulti
                            value={filterOrderType.map((type) => ({
                              value: type,
                              label: type,
                            }))}
                            onChange={(selectedOptions) => {
                              const selectedValues = selectedOptions
                                ? selectedOptions.map((option) => option.value)
                                : [];
                              setFilterOrderType(selectedValues);
                            }}
                          />
                        </div>

                        {/* Job Number filter */}
                        <div className='form-group col-3'>
                          <label className='form-label'>Sales Order:</label>
                          <Select
                            options={jobsData.map((job) => ({
                              value: job.OrderNum,
                              label: job.OrderNum,
                            }))}
                            isMulti
                            value={filterOrderNum.map((num) => ({
                              value: num,
                              label: num,
                            }))}
                            onChange={(selectedOptions) => {
                              const selectedValues = selectedOptions
                                ? selectedOptions.map((option) => option.value)
                                : [];
                              setFilterOrderNum(selectedValues);
                            }}
                          />
                        </div>

                        {/* Status filter */}
                        <div className='form-group  col-3'>
                          <label className='form-label'>Status:</label>
                          <Select
                            options={statusOptions?.map((status) => ({
                              value: status,
                              label:
                                status === 0
                                  ? "Pending"
                                  : status === 1
                                  ? "In Progress"
                                  : status === 2
                                  ? "Completed"
                                  : status === 3
                                  ? "Re-open"
                                  : status === 4
                                  ? "Hold"
                                  : status === 5
                                  ? "Resume"
                                  : "Pending",
                            }))}
                            isMulti
                            value={filterStatus?.map((value) => ({
                              value: value,
                              label:
                                value === 0
                                  ? "Pending"
                                  : value === 1
                                  ? "In Progress"
                                  : value === 2
                                  ? "Completed"
                                  : value === 3
                                  ? "Re-open"
                                  : value === 4
                                  ? "Hold"
                                  : value === 5
                                  ? "Resume"
                                  : "Pending",
                            }))}
                            onChange={(selectedOptions) => {
                              setFilterStatus(
                                selectedOptions
                                  ? selectedOptions?.map(
                                      (option) => option.value
                                    )
                                  : []
                              );
                            }}
                          />
                        </div>

                        {/* Apply filters button */}
                        <div className='d-flex justify-content-between align-items-center '>
                          <button
                            className='btn btn-primary'
                            onClick={applyFilters}
                          >
                            Apply
                          </button>
                          <div>
                              <FcClearFilters
                                className="ml-2"
                              size={25}
                              onClick={clearFilters}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='table-responsive col-12 mb-4'>
                      {/* Render DataTablePrime component */}
                      <DataTablePrime
                        lists={jobsListData}
                        headers={headers}
                          updateJobsList={updateJobsList}
                         
                        supervisors={supervisors}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              {/* Tab for Production Orders */}
              <TabPanel header='Production Orders'>
                <TabView>
                  {/* Render DataTablePrime component for each product category */}
                  <TabPanel header='All'>
                    <DataTableLines
                      lists={linesData}
                      paginator='true'
                      updateLinesData={updateLinesData}
                      supervisors={supervisors}
                    />
                  </TabPanel>
                  {productCategories?.map((category, index) => (
                    <TabPanel
                      key={index}
                      header={category}
                    >
                      <div className='table-responsive col-12 '>
                        <DataTableLines
                          paginator='true'
                          lists={linesData.filter(
                            (line) => line?.ProdGroup === category
                          )}
                          updateLinesData={updateLinesData}
                          supervisors={supervisors}
                        />
                      </div>
                    </TabPanel>
                  ))}
                </TabView>
              </TabPanel>
              {requestsData?.length ? (
                <TabPanel header='Reopen-Requests'>
                  <div className='table-responsive col-12 mb-4'>
                    <DataTableRequests
                      lists={requestsData}
                      updateRequestsData={updateRequestsData}
                    />
                  </div>
                </TabPanel>
              ) : null}
            </TabView>
          </div>
        </div>
      )}
    </>
  );
};

export default JobsList;
