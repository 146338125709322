import React, { useEffect, useState } from "react";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";
import Loader from "../../context/Loader/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useLoader } from "../../context/Loader/LoaderProvider";

const AuditLogs = () => {
  const [logsData, setLogsData] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [filters, setFilters] = useState({
    global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  });

  // Function to fetch audit logs data
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("logs");
      if (response.status === 200) {
        // Update logsData with modified timestamp format
        const modifiedLogsData = response.data?.data.map((log, index) => ({
          ...log,
          // created_at: new Date(log.created_at).toLocaleDateString("en-GB"),
          index: index + 1, // Add index for serial numbers
        }));
        setLogsData(modifiedLogsData);
      }
    } catch (error) {
      showErrorToast("Error fetching audit logs data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to handle global filter change
  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS },
    }));
  };

  // Render global search input
  /**
   * Render global search input component
   * @returns {JSX.Element} Global search input component
   */
  const renderHeader = () => {
    // Get the value from filters state
    const value = filters["global"]?.value || "";

    return (
      <div className='d-flex justify-content-between align-items-center'>
        {/* Header title */}
        <h4 className='mt-2 mb-4'>Audit Logs</h4>
        {/* Global search input */}
        <InputText
          type='search'
          value={value}
          onChange={onGlobalFilterChange}
          // Placeholder text
          placeholder='Global Search'
        />
      </div>
    );
  };

  const header = renderHeader();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content">
          <div className='card'>
            <div className='card-body'>
              <div className='table-responsive'>
                <DataTable
                  value={logsData}
                  dataKey='id'
                  header={header}
                  filters={filters}
                  onFilter={(e) => setFilters(e.filters)}
                  paginator
                  rows={20}
                  rowsPerPageOptions={[20, 50, 100, 150]}
                  paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                  currentPageReportTemplate='{first} to {last} of {totalRecords}'
                >
                  <Column
                    field='index'
                    header='Sr. No.'
                    // headerStyle={{ width: "10%" }}
                    sortable
                    bodyStyle={{ textAlign: "left" }}
                  />
                  <Column
                    field='user_emp_code'
                    header='Employee Code'
                    sortable
                  />
                  <Column
                    field='user_name'
                    header='User Name'
                    sortable
                  />
                  <Column
                    field='role_name'
                    header='Role'
                    sortable
                  />
                  <Column
                    field='created_at'
                    header='Created At'
                    sortable
                  />
                  <Column
                    field='user_email'
                    header='User Email'
                    sortable
                  />
                  <Column
                    field='user_mobile'
                    header='Contact No.'
                    sortable
                  />
                  <Column
                    field='action'
                    header='Activity'
                    sortable
                  />
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuditLogs;
