import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { LuFactory } from "react-icons/lu";
import { RxDashboard } from "react-icons/rx";
import { TiShoppingCart } from "react-icons/ti";
import { TbBriefcase2 } from "react-icons/tb";
import { RiCommunityLine } from "react-icons/ri";
import { GoWorkflow, GoOrganization } from "react-icons/go";
import { CiViewList } from "react-icons/ci";
import { BsCollection } from "react-icons/bs";
import { usePermissions } from "../context/permissions/PermissionsProvider";
import { LuLayoutTemplate } from "react-icons/lu";
import { PiUserList } from "react-icons/pi";
import { PiFactory } from "react-icons/pi";
import { PiUsersThree } from "react-icons/pi";
import { GrDocumentConfig } from "react-icons/gr";
import { FaRegListAlt } from "react-icons/fa";
import { TiFolderOpen } from "react-icons/ti";
import { FaRegFolderOpen } from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";



const SideBar = () => {
  // State to track whether the Masters list is open or closed
  const [isMastersOpen, setIsMastersOpen] = useState(false);
  const { authPermissions } = usePermissions(); // Get permissions from context
  const [activeTab, setActiveTab] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  // Function to toggle the Masters list
  const toggleMasters = () => {
    setIsMastersOpen((prev) => !prev);
  };
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <Fragment>
      <div
        id='header_top'
        className='header_top false'
      >
        <div className='container'>
          <div className='hleft' style={{overflow: 'scroll'}}>
            <div>
              {/* Dashboard Link */}
              <ul>
                <li className={activeTab === "/" ? "active-box" : ""}>
                  <Link
                    className={`nav-link icon app_inbox d-flex flex-column mt-2${
                      activeTab === "/" ? "active " : ""
                    }`}
                    to='/'
                    onClick={() => handleTabClick("/")}
                  >
                    <RxDashboard size={20} />
                    <span className='sidebar_text'>Dashboard</span>
                  </Link>
                </li>
                <li className={activeTab === "/job" ? "active-box" : ""}>
                  {authPermissions?.includes("Job Orders-List") ? (
                    <Link
                      className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                        activeTab === "/job" ? "active" : ""
                      }`}
                      to='/job'
                      onClick={() => handleTabClick("/job")}
                    >
                      <GoWorkflow size={20} />
                      <span className='sidebar_text'>Jobs</span>
                    </Link>
                  ) : null}
                </li>
                <li className={activeTab === "/auditlogs" ? "active-box" : ""}>
                  {Number(userData.role_id) !== 2 ? (
                    <Link
                      className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                        activeTab === "/auditlogs" ? "active" : ""
                      }`}
                      to='/auditlogs'
                      onClick={() => handleTabClick("/auditlogs")}
                    >
                      <CiViewList size={20} />
                      <span className='sidebar_text'>Audit Logs</span>
                    </Link>
                  ) : null}
                </li>
                <li className={activeTab === "/reports" ? "active-box" : ""}>
                  {Number(userData.role_id) !== 2 ? (
                    <Link
                      className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                        activeTab === "/reports" ? "active" : ""
                      }`}
                      to='/reports'
                      onClick={() => handleTabClick("/reports")}
                    >
                      <TbReportAnalytics size={20} />
                      <span className='sidebar_text'>Reports</span>
                    </Link>
                  ) : null}
                </li>
              </ul>

              {/* Masters List */}
              {Number(userData?.role_id) !== 2 ? (
                <ul className={`dropdown ${isMastersOpen ? "show" : ""}`}>
                  <li>
                    <span onClick={toggleMasters}>
                      <div
                        aria-current='page'
                        className='list-a nav-link icon app_inbox d-flex flex-column'
                        style={{ color: "white" }}
                      >
                        <GrDocumentConfig size={20} />
                        <span className='sidebar_text'>Masters</span>
                        <i
                          className={`caret-left ${
                            isMastersOpen ? "active" : ""
                          }`}
                        ></i>
                      </div>
                    </span>
                    {/* Nested Masters List */}
                    <ul className={`collapse ${isMastersOpen ? "show" : ""}`}>
                      {authPermissions?.includes("Roles-List") ? (
                        <li
                          className={activeTab === "/roles" ? "active-box" : ""}
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/roles" ? "active" : ""
                            }`}
                            to='/roles'
                            onClick={() => handleTabClick("/roles")}
                          >
                            <PiUserList size={20} />
                            <span className='sidebar_text'>Roles</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Company-List") ? (
                        <li
                          className={
                            activeTab === "/company" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/company" ? "active" : ""
                            }`}
                            to='/company'
                            onClick={() => handleTabClick("/company")}
                          >
                            <GoOrganization size={20} />
                            <span className='sidebar_text'>Company</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Plants-List") ? (
                        <li
                          className={activeTab === "/plant" ? "active-box" : ""}
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/plant" ? "active" : ""
                            }`}
                            to='/plant'
                            onClick={() => handleTabClick("/plant")}
                          >
                            <PiFactory size={20} />
                            <span className='sidebar_text'>Plants</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Users-List") ? (
                        <li
                          className={activeTab === "/users" ? "active-box" : ""}
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/users" ? "active" : ""
                            }`}
                            to='/users'
                            onClick={() => handleTabClick("/users")}
                          >
                            <PiUsersThree size={20} />
                            <span className='sidebar_text'>Users</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Product Categories-List") ? (
                        <li
                          className={
                            activeTab === "/productcategory" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/productcategory" ? "active" : ""
                            }`}
                            to='/productcategory'
                            onClick={() => handleTabClick("/productcategory")}
                          >
                            <TiShoppingCart size={20} />
                            <span className='sidebar_text'>Product Groups</span>
                          </Link>
                        </li>
                      ) : null}
                      {authPermissions?.includes("Products-List") ? (
                        <li
                          className={
                            activeTab === "/products" ? "active-box" : ""
                          }
                        >
                          <Link
                            className={`nav-link icon app_inbox d-flex flex-column mt-2 ${
                              activeTab === "/products" ? "active" : ""
                            }`}
                            to='/products'
                            onClick={() => handleTabClick("/products")}
                          >
                            <LuLayoutTemplate size={20} />
                            <span className='sidebar_text'>
                              Stage Templates
                            </span>
                          </Link>
                        </li>
                      ) : null}
                    </ul>
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SideBar;
