import "./App.css"; // Import CSS file for styling
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Layouts/Main"; // Import Main layout component

// Import pages
import DashBoard from "./Pages/DashBoard/DashBoard";
import UsersList from "./Pages/Users/UsersList";
import AddEditUsers from "./Pages/Users/AddEditUser";
import JobsList from "./Pages/Jobs/JobsList";
import ForgotPassword from "./Pages/Auth/ForgotPassword";
import Profile from "./Pages/Profile/Profile";
import Reports from "./Pages/Reports/Reports";
import Plant from "./Pages/Plants/Plants";
import Product from "./Pages/Product/Product";
import AddEditProduct from "./Pages/Product/AddEditProduct";
import UserPermission from "./Pages/Users/UserPermission";
import Roles from "./Pages/Roles/Roles";
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import Company from "./Pages/Company/Company";
import AddEditCompany from "./Pages/Company/AddEditCompany";
import AddEditPlant from "./Pages/Plants/AddEditPlant";
import AddEditRole from "./Pages/Roles/AddEditRole";
import AuditLogs from "./Pages/AuditLogs/AuditLogs";
import RolePermission from "./Pages/Roles/RolePermission";
import JobView from "./Pages/Jobs/JobView";
import RequestsList from "./Pages/Requests/RequestList";
import ProductCategory from "./Pages/ProductCategory/ProductCategory";
import AddEditProductCategory from "./Pages/ProductCategory/AddEditProductCategory";
import AuthMain from "./Layouts/AuthMain";
import Flow from "./Pages/Roles/Flow";
import NotFound from "./components/NotFound/NotFound";
import Forbidden from "./components/Forbidden/Forbidden";
import UnderMaintenance from "./Pages/UnderMaintenance/UnderMaintenance";

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
        {/* Main layout routes */}
        <Route
          path='/'
          element={<Main />} //there is outlet in Main which will render pages according to path*/
        >
          <Route
            path=''
            element={<DashBoard />}
          />
          {/* Dashboard */}
          <Route
            path='profile'
            element={<Profile />}
          />
          <Route
            path='reports'
            element={<Reports />}
          />
          {/* Users routes */}
          <Route path='users'>
            <Route
              path=''
              element={<UsersList />}
            />
            <Route
              path='add'
              element={<AddEditUsers />}
            />
            <Route
              path='edit/:id'
              element={<AddEditUsers />}
            />
            <Route
              path='permissions/:id'
              element={<UserPermission />}
            />
          </Route>
          {/* Plant routes */}
          <Route path='plant'>
            <Route
              path=''
              element={<Plant />}
            />
            <Route
              path='add'
              element={<AddEditPlant />}
            />
            <Route
              path='edit/:id'
              element={<AddEditPlant />}
            />
          </Route>
          {/* Product category routes */}
          <Route path='productcategory'>
            <Route
              path=''
              element={<ProductCategory />}
            />
            <Route
              path='add'
              element={<AddEditProductCategory />}
            />
            <Route
              path='edit/:id'
              element={<AddEditProductCategory />}
            />
          </Route>
          {/* Products routes */}
          <Route path='products'>
            <Route
              path=''
              element={<Product />}
            />
            {/* Add/Edit Product */}
            <Route path='add'>
              <Route
                path=''
                element={<AddEditProduct />}
              />
            </Route>
            <Route
              path='edit/:id'
              element={<AddEditProduct />}
            />
          </Route>
          {/* Job routes */}
          <Route path='job'>
            <Route
              path=''
              element={<JobsList />}
            />
            <Route
              path='view/:id'
              element={<JobView />}
            />
          </Route>
          {/* Requests routes */}
          <Route path='request'>
            <Route
              path=''
              element={<RequestsList />}
            />
            <Route
              path='view/:id'
              element={<JobView />}
            />
          </Route>
          {/* Roles routes */}
          <Route path='roles'>
            <Route
              path=''
              element={<Roles />}
            />
            <Route
              path='add'
              element={<AddEditRole />}
            />
            <Route
              path='edit/:id'
              element={<AddEditRole />}
            />
            <Route
              path='permissions/:id'
              element={<RolePermission />}
            />
            <Route
              path='flow'
              element={<Flow />}
            />
          </Route>
          {/* Company routes */}
          <Route path='company'>
            <Route
              path=''
              element={<Company />}
            />
            <Route
              path='add'
              element={<AddEditCompany />}
            />
            <Route
              path='edit/:id'
              element={<AddEditCompany />}
            />
          </Route>
          <Route
            path='auditlogs'
            element={<AuditLogs />}
          />
          {/* 404 route */}
          <Route
            path='/*'
            element={<NotFound />}
          />
          {/* 403 route */}
          <Route
            path='/forbidden'
            element={<Forbidden />}
          />{" "}
        </Route>
        {/* Auth layout routes */}
        <Route
          path='/'
          element={<AuthMain />}
        >
          <Route
            path='login'
            element={<Login />}
          />
          <Route
            path='register'
            element={<Register />}
          />
          <Route
            path='request-password'
            element={<ForgotPassword />}
          />
          <Route
            path='maintenance'
            element={<UnderMaintenance />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
