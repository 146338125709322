import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import logo from "../../assets/images/payments/tempsens.png";
import logo1 from "../../assets/Logos/2000.png";
import logo2 from "../../assets/Logos/3000.png";
import logo3 from "../../assets/Logos/4000.png";
import logo4 from "../../assets/Logos/5000.png";

const ForgotPassword = () => {
  const navigate = useNavigate();

  // Form validation schema
  const loginSchema = Yup.object({
   
    emp_code: Yup.string().required("Employee Code is Required"),
   
  });

  // Initial form values
  const initialValues = {
    
    emp_code: "",
   
  };

  // Form submission handling
  const handleSubmit = async (values) => {
    try {
      const response = await axiosPrivate.post("request-password", values);
      if (response.status === 200) {
        
        showSuccessToast("Request Sent!");
        navigate("/login");
      }
    } catch (error) {
      showErrorToast(error.response?.data?.errors[0]?.msg || error.message);
    }
  };

  // Formik form handling
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      {/* Left side of the login page */}
      <div className='auth_left'>
        <div className='card'>
          <div className='text-center mb-2'>
            {/* Logo */}
            <Link
              className='header-brand'
              to='/'
            >
              <img
                width={50}
                height={50}
                src={logo}
                alt='headerImg'
              />
            </Link>
          </div>
          <div className='card-body'>
            <form onSubmit={formik.handleSubmit}>
              {/* Forgot Password form */}
              <div className='card-title'>Request for new password</div>
             
              {/* Employee Code */}
              <div className='form-group'>
                <label className='form-label'>Employee Code</label>
                <input
                  type='number'
                  name='emp_code'
                  className='form-control'
                  value={formik.values.emp_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder='Employee Code'
                />
                {formik.touched.emp_code && formik.errors.emp_code && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.errors.emp_code}
                  </div>
                )}
              </div>
             
              {/* Submit button */}
              <div className='form-footer'>
                <input
                  type='submit'
                  className='btn btn-primary btn-block'
                  value='Request'
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Right side of the login page with carousel */}
      <div className='auth_right'>
        <svg className="bglogo" width="595" height="398" viewBox="0 0 595 398" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M210.714 0H417.916L207.202 193.447H0L210.714 0Z" fill="#EEEEEE" />
          <path d="M239.395 202.229L428.745 18.1465V223.885L239.395 397.139V202.229Z" fill="#EEEEEE" />
          <path d="M671.36 0H464.157L674.871 193.447H882.074L671.36 0Z" fill="#EEEEEE" />
          <path d="M642.678 202.229L453.328 18.1465V223.885L642.678 397.139V202.229Z" fill="#EEEEEE" />
        </svg>
        <div className="row logoRow">
          <div className="col">
            <div className="card logoCard ">
              <div className="image_card_logo">
                <img
                  className="image_card_logo"
                  src={logo1}
                  alt='logo'
                />
              </div>

            </div>
            <div className="card logoCard">
              <img
                className="image_card_logo"
                src={logo2}
                alt='logo'
              />
            </div>
          </div>
          <div className="col">
            <div className="card logoCard">
              <img
                className="image_card_logo"
                src={logo3}
                alt='logo'
              />
            </div>
            <div className="card logoCard">
              <img
                className="image_card_logo"
                src={logo4}
                alt='logo'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
