import React from "react";
import "./Jobcard.css"; // Import CSS file for styling
import { HiOutlineArrowSmallRight } from "react-icons/hi2";
import { Link } from "react-router-dom";

const JobCard = ({ data, isSuperAdmin }) => {
  // Determine the job numbers dynamically based on data received
  const jobs = isSuperAdmin
    ? [
        {
          Job_type: "Users",
          job_number: data ? data.all_users : 0, 
        },
        {
          Job_type: "Stage Templates",
          job_number: data ? data.products : 0, 
        },
        {
          Job_type: "Plants",
          job_number: data ? data.plants : 0, 
        },
        {
          Job_type: "Active Users",
          job_number: data ? data.all_active_users : 0, 
        },
      ]
    : [
        {
          Job_type: "Assigned Jobs",
          job_number: data ? data.length : 0, 
        },
        {
          Job_type: "Lines On Hold",
          job_number: data ? data.filter(stage => stage.status === 2)?.length : 0, 
        },
        {
          Job_type: "Overdue Jobs",
          job_number: data ? data.filter(stage => (typeof stage?.flags) === 'string' ? JSON.parse(stage?.flags)?.includes('Overdue') : stage?.flags?.includes('Overdue'))?.length : 0,
        },
        {
          Job_type: "Today's Due Jobs",
          job_number: data ? data.filter(stage => new Date(stage.planned_end).setHours(0,0,0,0) === new Date().setHours(0,0,0,0))?.length : 0, // Example placeholder value, replace with actual data
        },
      ];

  return (
    <div>
      {/* Render job cards */}
      <div className='row justify-content-between'>
        {jobs.map((job, index) => (
          <div
            className='job-card col'
            key={index}
          >
            <p className='job-number'>{job.job_number}</p>
            <p className='job-info'>
              <strong>{job.Job_type}</strong>
            </p>
          </div>
        ))}
      </div>

      {/* View All link */}
      {/* Uncomment the following code to display the "View All" link */}
      {/* <div className="d-flex justify-content-end w-100 mt-2">
        <Link style={{ color: "var(--red)" }} to="/job">
          View All
          <HiOutlineArrowSmallRight />
        </Link>
      </div> */}
    </div>
  );
};

export default JobCard;
