import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash } from "react-icons/fa";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import { usePermissions } from "../../context/permissions/PermissionsProvider";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext"; // Import InputText component for search functionality
import { FilterMatchMode } from "primereact/api"; // Import FilterMatchMode for configuring search behavior
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const Plant = () => {
  const [plantsData, setPlantsData] = useState([]);
  const { isLoading, setIsLoading } = useLoader(); // Get the isLoading state and setIsLoading function from LoaderProvider
  const { authPermissions } = usePermissions();
  const [plantId, setPlantId] = useState(null);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false); 
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }, // State for global search filter
  });

  // Function to handle plant deletion
  // const plantDeleteHandler = async (id) => {
  //   try {
  //     const response = await axiosPrivate.delete(`plant/${id}`);
  //     if (response.status === 200) {
  //       showSuccessToast("Plant deleted successfully");
  //       fetchData(); // Refresh data after deletion
  //     }
  //   } catch (error) {
  //     showErrorToast(error.message);
  //   }
  // };

  useEffect(() => {
    if (plantId) {
      setDeleteConfirmationVisible(true);
    }
  }, [plantId]);  
  const plantDeleteHandler = async (id) => {
    try {
      const response = await axiosPrivate.delete(`plant/${id}`);
      if (response.status === 200) {
        showSuccessToast("Plant deleted successfully");
        fetchData();

      }
    } catch (error) {
      showErrorToast(error?.response?.data?.errors[0]?.msg || error.message);
    } finally {
      setPlantId(null)
    }
  };

  // Function to fetch plant data
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("plant");
      if (response.status === 200) {
        setPlantsData(response.data?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on component mount
  }, []);

  // Prepare data for rendering in DataTable
  const plantsListData = plantsData?.map((value, index) => {
    let buttons = [];

    // Add edit button
    if (authPermissions?.includes("Plants-Update")) {
      buttons.push(
        <Link
          key={`editButton_${value.id}`}
          to={`/plant/edit/${value.id}`}
          className='btn btn-link'
          style={{ border: "none", background: "none", padding: "0" }}
          title='Edit'
        >
          <FaEdit
            color='green'
            size={13}
          />
        </Link>
      );
    }

    // Add delete button
    // if (authPermissions?.includes("Plants-Delete")) {
    //   buttons.push(
    //     <button
    //       key={`deleteButton_${value.id}`}
    //       className='btn btn-link m-0'
    //       style={{ border: "none", background: "none" }}
    //       onClick={() => plantDeleteHandler(value.id)}
    //       title='Delete'
    //     >
    //       <FaTrash
    //         color='red'
    //         size={13}
    //       />
    //     </button>
    //   );
    // }

    return { ...value, index: index + 1, action: buttons };
  });

  // Function to handle global filter change
  const onGlobalFilterChange = (event) => {
    const { value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      global: { value: value, matchMode: FilterMatchMode.CONTAINS }, // Set the value and matchMode for global search
    }));
  };

  // Render global search input
  const renderHeader = () => {
    const value = filters["global"] ? filters["global"].value : "";

    return (
      <div className='d-flex justify-content-between w-100'>
        {authPermissions?.includes("Plants-Create") && (
          <Link
            to='add'
            className='text-decoration-none'
          >
            <button className='btn btn-primary'>
              <i className='fe fe-plus mr-2' />
              Add
            </button>
          </Link>
        )}
        <InputText
          type='search'
          value={value || ""}
          onChange={onGlobalFilterChange}
          placeholder='Global Search'
        />
      </div>
    );
  };

  const header = renderHeader(); // Call the renderHeader function to get the search input component
  const deleteBodyTemplate = (rowData) => {
    return (
      <button
        type='button'
        onClick={() => {
          setPlantId(rowData.id);
        }}
        title='Delete'
        style={{ border: "none", background: "none", padding: "6px", textAlign: "left" }}
      >
        <FaTrash
          color='red'
          size={13}

        />
      </button>
    );
  }

  return (
    <>
      {isLoading ? ( // Render Loader component based on isLoading state
        <Loader />
      ) : (
        <div className='content'>
          {/* Render plant management section */}

          <div className='page-header'>
            <h4 className='m-1 pl-3'>Plants</h4>
          </div>

          <div className='card'>
            <div className='card-body'>
              <div className='table-responsive'>
                {/* Render DataTable component */}
                <DataTable
                  value={plantsListData}
                  dataKey='id'
                  paginator
                  rowsPerPageOptions={[10, 20, 30, 50]}
                  paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                  currentPageReportTemplate='{first} to {last} of {totalRecords}'
                  rows={10}
                  
                  header={header} // Pass the search input component as header
                  filters={filters} // Pass filters state for search functionality
                  onFilter={(e) => setFilters(e.filters)} // Update filters state on filter change
                >
                  <Column
                    field='index'
                    header='Sr. No.'
                    sortable
                    bodyStyle={{ textAlign: "left" }}
                    style={{ width: "8%" }}
                  />
                  <Column
                    field='name'
                    header='Plant'
                    sortable
                  />
                  <Column
                    field='code'
                    header='Plant Code'
                    sortable
                  />
                  <Column
                    field='company'
                    header='Company'
                    sortable
                  />
                  <Column
                    field='action'
                    header='Action'
                    headerStyle={{ width: "8%", paddingLeft: "105px" }}
                    bodyStyle={{ textAlign: "right" }}
                  />
                  <Column
                    field='delete_handler'
                    body={deleteBodyTemplate}
                    headerStyle={{ width: "15%" }}
                    bodyStyle={{ textAlign: "left" }}
                  />
                </DataTable>
              </div>
            </div>
          </div>
          <Dialog
            visible={deleteConfirmationVisible}
            onHide={() => {
              setDeleteConfirmationVisible(false);
              setPlantId(null);
            }}
            header='Confirmation'
            footer={
              <>
                <Button
                  label='Cancel'
                  className='p-button-text mr-2'
                  onClick={() => {
                    setPlantId(null);
                    setDeleteConfirmationVisible(false);
                  }}
                />
                <Button
                  label='Delete'
                  className='p-button-danger'
                  onClick={() => {
                    plantDeleteHandler(plantId);
                    setDeleteConfirmationVisible(false);
                  }}
                />
              </>
            }
          >
            <p>Are you sure you want to delete this plant?</p>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default Plant;
