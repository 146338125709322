import { createContext, useMemo, useState, useContext } from "react";

// Create a context for the loader state
const LoaderContext = createContext();

const LoaderProvider = ({ children }) => {
  // State to track whether loader is active or not
  const [isLoading, setIsLoading] = useState(false);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      isLoading, // Current loading state
      setIsLoading, // Function to update loading state
    }),
    [isLoading, setIsLoading]
  );

  return (
    // Provide the loader context value to its children
    <LoaderContext.Provider value={contextValue}>
      {children}
    </LoaderContext.Provider>
  );
};

// Custom hook to access loader context
export const useLoader = () => {
  // Use the loader context to access loading state and set-loading function
  return useContext(LoaderContext);
};

export default LoaderProvider;
